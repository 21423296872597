<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <h3>Permit County</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>

        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="permitCountyRequest.status"
                        label="Active"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Name</label>
                    <v-text-field
                        v-model="permitCountyRequest.name" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-expansion-panels popout style="margin: 0;">

                <!-- CITIES -->
                <v-col
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon color="primary" left>
                                        mdi-city
                                    </v-icon>
                                    Cities
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row v-for="(item, index) in permitCountyRequest.listCity" :key="index" style="height: 60px;" >
                                <v-col 
                                    cols="12"
                                    lg="5"
                                    md="5"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.stateSelected"
                                        :items="listState"
                                        item-text="codeDescription"
                                        item-value="id"
                                        clearable
                                        outlined
                                        dense
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="5"
                                    sm="12"
                                >
                                    <v-combobox
                                        v-model="item.citySelected"
                                        :items="listCity"
                                        item-text="codeStateDescriptionCity"
                                        item-value="id"
                                        clearable
                                        outlined
                                        @click="listCityByState(item.stateSelected)"
                                        dense
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="2"
                                    lg="1"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewCity(item)" 
                                                color="blue" 
                                                small
                                                :key="index"
                                            >
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New City</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deleteCity(index)" 
                                                color="red" 
                                                small
                                                :disabled="permitCountyRequest.listCity.length <= 1"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete City</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

                <!-- PERMITS -->
                <v-col
                    v-if="false"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon color="primary" left>
                                        mdi mdi-license
                                    </v-icon>
                                    Permits
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-row v-for="(item, index) in permitCountyRequest.listPermit" :key="index" style="height: 60px;" >
                                <v-col 
                                    cols="10"
                                    lg="6"
                                    md="10"
                                    sm="10"
                                >
                                    <v-combobox
                                        v-model="item.permitSelected"
                                        :items="listPermit"
                                        item-text="description"
                                        item-value="id"
                                        clearable
                                        outlined
                                        dense
                                    ></v-combobox>
                                </v-col>
                                <v-col
                                    cols="12"
                                    lg="5"
                                    md="6"
                                    sm="12"
                                >
                                    <v-file-input
                                        v-model="item.documentListFiles" 
                                        accept=".pdf,.doc,.docx"
                                        small-chips
                                        multiple
                                        outlined
                                        placeholder="Add Document Template"
                                        prepend-inner-icon="mdi-paperclip"
                                        prepend-icon=""
                                        @change="addFilesToList(item.documentListFiles, item)"
                                    ></v-file-input>
                                </v-col>
                                <v-col 
                                    cols="2"
                                    lg="1"
                                    md="2"
                                    sm="2"
                                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                >
                                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="addNewPermit(item)" 
                                                color="blue" 
                                                small
                                                :key="index"
                                            >
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Permit</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="btnActions"
                                                outlined
                                                fab
                                                x-small
                                                v-on="on"
                                                @click="deletePermit(index)" 
                                                color="red" 
                                                small
                                                :disabled="permitCountyRequest.listPermit.length <= 1"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Permit</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>

            </v-expansion-panels>

            <ActionButtons
                :request="permitCountyRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />

        </v-form>

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";
    import ActionButtons from "@/components/Layout/ActionButtons";
    import { TYPE_DOCUMENT, TYPE_ATTACHMENT } from "@/utilities/Enums";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,

			permitCountyRequest: {
                id: 0,
                status: 1,
                name: "",
                listPermit: [],
                listCity: [],
            },

            listPermit: [],
            listPermitFiles: [],
            listState: [],
            listCity: [],

            loading: false,

            validations: {
                required: required
            }
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {

            async getPermitCounty(id) {
                let response = await this.$store.dispatch("permitCountyModule/GetById", id);

                this.permitCountyRequest = response.result;
            },

            async getRegister() {

                if (this.id !== 0) {

                    await this.getPermitCounty(this.id);

                    if (this.permitCountyRequest.listPermit.length == 0) {
                        this.addNewPermit();
                    }
                    else {
                        this.addPermitAdded()
                    }

                    if (this.permitCountyRequest.listCity.length == 0) {
                        this.addNewCity();
                    }
                    else {
                        this.addCityAdded()
                    }
                    
                    this.permitCountyRequest.listPermit.forEach(async itemPermit => {
                        
                        itemPermit.documentFiles = [];
                        itemPermit.documentListFiles = [];
                        
                        let attachment = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PERMITTING.value, idKey: itemPermit.id }); 

                        let item = {
                            id: attachment[0].id,
                            file: attachment[0].file,
                            fileName: attachment[0].fileName,
                            deleted: false,
                        }

                        itemPermit.documentFiles.push(item);
                        itemPermit.documentListFiles.push(new File([], attachment[0].fileName));
                    })
                }
                else {
                    this.permitCountyRequest.status = 1;
                    this.addNewPermit();
                    this.addNewCity();
                }
            },

            //CITY
            async deleteCity(index) {
                this.permitCountyRequest.listCity.splice(index, 1);
                
                let lastIndex = this.permitCountyRequest.listCity.length - 1;

                this.permitCountyRequest.listCity[lastIndex].showAddNewItem = true;
            },

            async addNewCity(itemAtual) {

                if (itemAtual !== null && itemAtual !== undefined) {
                    itemAtual.showAddNewItem = false;
                }

                this.permitCountyRequest.listCity.push({
                    permitSelected: null,
                    showAddNewItem: true
                })

                this.addCityAdded()
            },

            async addCityAdded() {

                this.listCityAdded = []
                this.permitCountyRequest.listCity.forEach(itemCity => {
                    
                    if (itemCity.permitSelected != null && itemCity.permitSelected != undefined) {
                        this.listCityAdded.push({
                            id: itemCity.permitSelected.id,
                            description: itemCity.permitSelected.description
                        })
                    }
                })
            },

            async listCityByState(stateSelected) {

                this.listCity = [];

                if (stateSelected != null && stateSelected != undefined) {

                    this.listCity = await this.$store.dispatch(`cityModule/List`, stateSelected.id);
                }
            },

            
            //PERMIT
            async deletePermit(index) {
                this.permitCountyRequest.listPermit.splice(index, 1);
                
                let lastIndex = this.permitCountyRequest.listPermit.length - 1;

                this.permitCountyRequest.listPermit[lastIndex].showAddNewItem = true;
            },

            async addNewPermit(itemAtual) {

                if (itemAtual !== null && itemAtual !== undefined) {
                    itemAtual.showAddNewItem = false;
                }

                this.permitCountyRequest.listPermit.push({
                    permitSelected: null,
                    showAddNewItem: true
                })

                this.addPermitAdded()
            },

            async addPermitAdded() {

                this.listPermitAdded = []
                this.permitCountyRequest.listPermit.forEach(itemPermit => {
                    
                    if (itemPermit.permitSelected != null && itemPermit.permitSelected != undefined) {
                        this.listPermitAdded.push({
                            id: itemPermit.permitSelected.id,
                            description: itemPermit.permitSelected.description
                        })
                    }
                })
            },

            addFilesToList(listFiles, itemPermit) {

                let id = 0;

                if (itemPermit.documentFiles == null || itemPermit.documentFiles == undefined) {
                    itemPermit.documentFiles = [];
                }
                else {
                    id = itemPermit.documentFiles[0].id
                }

                if (listFiles) {

                    listFiles.forEach(itemFile => {

						if (itemFile) {

                            let fileName = itemFile.name;

                            if (itemFile.name && itemFile.name.length > 15) {
                                fileName = itemFile.name.toString().substring(0, 12) + "..."
                            }

                            let item = {
                                id: id,
                                file: itemFile,
                                fileName: fileName,
                                deleted: false,
                            }

                            itemPermit.documentFiles.push(item);
						}	
					});
                }
            },

            async uploadFiles(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async saveFiles() {

                let allResult = [];

                this.listPermitFiles.forEach(async itemPermit => {

                    itemPermit.documentFiles.forEach(async itemFile => {

                        let result = await this.uploadFiles(itemFile.id, itemPermit.id, itemFile.file, TYPE_DOCUMENT.PERMITTING_DOCUMENT_MODEL.value, itemFile.deleted);

                        if (result.success == false) {
                            allResult.push({
                                typeDocument: TYPE_DOCUMENT.PERMITTING_DOCUMENT_MODEL.description,
                                message: result.message
                            })
                        }
                    })

                    if (allResult.length > 0) {
                        let message = ""

                        await allResult.forEach(itemResult => {
                            message += `The [${itemResult.typeDocument}] reported the error: [${itemResult.message}] \n`;
                        })

                        this.showToast("error", "Warning!", message);

                        return false;
                    }
                    else {
                        return true;
                    }

                })
            },

            async getLists() {
                
                this.listPermit = await this.$store.dispatch("permitModule/List");
                this.listState = await this.$store.dispatch("stateModule/List");
            },

            async cancel() {
                this.$router.push({ path: "/common/permitCountyList" });
            },

            async validatePermitAndCity() {

                let valid = true;

                if (this.permitCountyRequest.listPermit !== null && this.permitCountyRequest.listPermit !== undefined) {

                    let listPermit = [...this.permitCountyRequest.listPermit];
                    let newListPermit = [];

                    this.permitCountyRequest.listPermit.forEach(itemPermit => {
                        
                        if (itemPermit.permitSelected != null && itemPermit.permitSelected != undefined) {
                            
                            let existItem = listPermit.filter(ems => ems.permitSelected.id === itemPermit.permitSelected.id);

                            if (existItem.length > 1 && valid) {
                                this.showToast("warning", "Warning!", `Permit [${itemPermit.permitSelected.description}] is duplicated!`);
                                valid = false;
                            }
                            else {
                                newListPermit.push(itemPermit);
                            }
                        }
                    });

                    if (valid) {
                        this.permitCountyRequest.listPermit = newListPermit;
                    }
                }

                if (this.permitCountyRequest.listCity !== null && this.permitCountyRequest.listCity !== undefined) {

                    let listCity = [...this.permitCountyRequest.listCity];
                    let newListCity = [];

                    this.permitCountyRequest.listCity.forEach(itemCity => {
                        
                        if (itemCity.citySelected != null && itemCity.citySelected != undefined) {
                            
                            let existItem = listCity.filter(ems => ems.citySelected.id === itemCity.citySelected.id);

                            if (existItem.length > 1 && valid) {
                                this.showToast("warning", "Warning!", `City [${itemCity.citySelected.codeStateDescriptionCity}] is duplicated!`);
                                valid = false;
                            }
                            else {
                                newListCity.push(itemCity);
                            }
                        }
                    });

                    if (valid) {
                        this.permitCountyRequest.listCity = newListCity;
                    }
                }

                return valid;
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {
                    if (await this.validatePermitAndCity()) {
                        this.loading = true;
                        this.showLoading();

                        if (this.permitCountyRequest.status === null) {
                            this.permitCountyRequest.status = 0;
                        }

                        this.listPermitFiles = [...this.permitCountyRequest.listPermit]

                        const result = await this.$store.dispatch("permitCountyModule/CreateUpdate", this.permitCountyRequest);

                        await this.getPermitCounty(result.id);
                            
                        this.permitCountyRequest.listPermit.forEach(itemPermit => {
                            this.listPermitFiles.filter(item => item.permitSelected.id == itemPermit.permitSelected.id)[0].id = itemPermit.id;
                        });

                        if (this.saveFiles() && result.success === true) {
                            this.showToast("success", "Success!", result.message);
                            this.cancel();
                        }
                        else {
                            this.loading = false;
                            this.showToast("error", "Warning!", result.message);
                        }
                    }
                }
            },
        },

        created() {
            this.showLoading();

            this.getLists();
            this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .colButtonList {
        padding-top: 12px;    
    }

    .colButtonList2 {
        margin-top: inherit;
    }

    @media only screen and (max-width: 647px) {
        .colButtonList {
            margin-top: -30px;
            display: flex;
            align-items: center;
        }

        .colButtonList2 {
             margin-top: -8px;
        }

        .btnActions {
            margin-bottom: 5px;
        }
    }
</style>